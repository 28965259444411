import * as XLSX from 'xlsx'

function convertJsonToArray(jsonData, fileName) {
  downloadJsonAsXlsx(jsonData, fileName)
}

function downloadJsonAsXlsx(jsonData, filename) {
  const worksheet = XLSX.utils.json_to_sheet(jsonData)
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, "Foglio")
  const buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" })
  const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
  const link = document.createElement("a")
  link.href = URL.createObjectURL(blob)
  link.download = filename + ".xlsx"
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const useDownloader = () => {
  return convertJsonToArray
}
