import { useWindowWidth } from "@react-hook/window-size";
import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { getImageUrl } from "../../utils/getImageUrl";
import getLinkCorretto from "../../utils/getLinkCorretto";
import FormatoBottiglia from "../formato-bottiglia";
import { WishlistPanel } from "../wishlistPanel";

export default function VinoSingolo({ tipoVista, isOpen, idVinoProp }) {
	const params = useParams();
	const idVino = idVinoProp || params.idVino;
	const allWines = useSelector((state) => state.wines.allWines);
	const vino = allWines.find((wine) => wine.id === parseInt(idVino, 10));
	const history = useHistory();
	const locale = useSelector((state) => state.tenute.locale);

	const testo = useRef(null);
	const windowWidth = useWindowWidth();

	const [isSmallScreen, setIsSmallScreen] = useState();

	const testi = { it: "testo_italiano", en: "testo_inglese", de: "testo_tedesco", ch: "testo_cinese" };

	// Regolo lo scroll in base al contenuto
	useEffect(() => {
		if (!testo.current) {
			return;
		}

		const rectTesto = testo.current.getBoundingClientRect();

		if (rectTesto.bottom > window.innerHeight / 2) {
			setIsSmallScreen(true);
		} else {
			setIsSmallScreen(false);
		}
	}, [testo, windowWidth]);

	if (!vino) {
		history.push("/prodotti");
		return null;
	}

	// const isInWishlist = wishlist.find(w => w.id === vino.id)

	// function addToWishlist(wine) {
	//   dispatch({ type: "WINES_WISHLIST_ADD", payload: wine })
	// }

	function Container(props) {
		if (windowWidth < 768) {
			return <div {...props}>{props.children}</div>;
		}

		return <motion.div {...props}>{props.children}</motion.div>;
	}

	return (
		<Container
			className="Vino__Container fixed text-black z-20 bottom-0 lg:flex w-7/10 bg-tenute"
			animate={{ left: isOpen ? 0 : "33vw", transition: { type: "tween", duration: 0.5 }, opacity: 1 }}
			initial={{ left: isOpen ? 0 : "33vw", opacity: 1 }}
			exit={{ opacity: tipoVista === "griglia" ? 0 : 1, transition: { delay: 2 } }}
			key={`${idVino}-vino`}
		>
			<div style={{ width: "100vw" }}>
				<Container
					className="Vino__Foto relative lg:absolute h-full bg-brown overflow-hidden"
					initial={{ width: 0 }}
					animate={{
						width: windowWidth > 828 ? "67vw" : "100vw",
						transition: { type: "tween", duration: 1 },
					}}
					exit={{ opacity: 1, transition: { duration: 1 } }}
				>
					<motion.img
						initial={{ scale: 1.2 }}
						animate={{ scale: 1, transition: { type: "tween", duration: 1 } }}
						className="w-full h-full object-cover object-center"
						src={getImageUrl(vino.Foto.url, 1800)}
						alt="Copertina"
					/>

					{!isOpen && vino.mostra_logo_ocm && (
						<motion.img
							className="absolute block right-0 top-1/2 transform -translate-y-1/2 px-2 lg:px-4"
							initial={{ opacity: 0 }}
							animate={{ opacity: 1, transition: { duration: 0.5, type: "tween", delay: 1 } }}
							src={`${process.env.PUBLIC_URL}/logo-ocm.svg`}
							alt="ocm logo"
							style={{ maxHeight: "70%" }}
						/>
					)}

					{isOpen && vino.mostra_logo_ocm && (
						<motion.img
							className="absolute block left-0 top-1/2 transform -translate-y-1/2 px-2 lg:px-4"
							initial={{ opacity: 0 }}
							animate={{ opacity: 1, transition: { duration: 0.5, type: "tween", delay: 1 } }}
							src={`${process.env.PUBLIC_URL}/logo-ocm.svg`}
							alt="ocm logo"
							style={{ maxHeight: "70%" }}
						/>
					)}
				</Container>
			</div>

			<div
				className={`Vino__Testo lg:absolute lg:h-full right-0 bg-tenute text-black p-4 ${
					isSmallScreen
						? windowWidth < 768
							? "overflow-y-visible"
							: "overflow-y-auto"
						: windowWidth < 768
						? "overflow-y-visible"
						: "overflow-y-hidden"
				}`}
			>
				{/* Nome */}
				<motion.div
					className="Tenuta__Nome font-BodoniBook text-4xl lg:text-5xl leading-none mt-2"
					initial={{ opacity: 0, y: 25 }}
					animate={{ opacity: 1, y: 0, transition: { delay: 0.5, type: "tween", duration: 0.6 } }}
					exit={{ opacity: 0 }}
					/* ref={nome} */
				>
					{vino.Nome}
				</motion.div>

				{/* Denominazione */}
				<motion.div
					className="Tenuta__Denominazione font-LatoBold mt-2 text-gray uppercase text-sm tracking-2"
					initial={{ opacity: 0, y: 25 }}
					animate={{ opacity: 1, y: 0, transition: { delay: 0.5, type: "tween", duration: 0.6 } }}
					exit={{ opacity: 0 }}
				>
					{vino.Denominazione}
				</motion.div>

				{/* Testo */}
				<motion.div
					className="Tenuta__Testo font-BodoniBook mt-12 text-xl leading-relaxed overflow-y-auto pr-4"
					initial={{ opacity: 0, y: 25 }}
					animate={{ opacity: 1, y: 0, transition: { delay: 0.75, type: "tween", duration: 0.6 } }}
					exit={{ opacity: 0 }}
					ref={testo}
				>
					{vino[testi[locale]]}
				</motion.div>
				<motion.div
					initial={{ opacity: 0, y: 25 }}
					animate={{ opacity: 1, y: 0, transition: { delay: 1, type: "tween", duration: 0.6 } }}
					className="mt-2 cursor-pointer"
				>
					{vino.ExternalLink && (
						<a target="_blank" rel="noopener noreferrer" className="font-BodoniBook text-sm text-brown" href={vino.ExternalLink.Url}>
							{vino.ExternalLink.Label}
						</a>
					)}
				</motion.div>
				{/* Btn wishlist */}
				<div className="my-8 w-full sm:w-1/2 md:w-1/3 lg:w-full">
					<WishlistPanel showPopup noWrap wine={vino} showIcon={windowWidth < 768 || windowWidth > 1024 ? true : false} />
					{/* <WishlistPanel showPopup album={album} noWrap wine={wine} moveItem={moveAndDelete ? e => removeItem(e) : null} />  */}
				</div>

				{/* <motion.div
          initial={{ opacity: 0, y: 25 }}
          animate={{ opacity: 1, y: 0, transition: { type: "tween", duration: 0.6, delay: 0.5 } }}
          exit={{ opacity: 0 }}
          className={`Tenuta__BtnWishlist ${
            isSmallScreen ? "Tenuta__BtnWishlist--small" : "lg:absolute bottom-0 left-0 pl-12"
          }`}
        >
          {!isInWishlist && (
            <button
              className="font-LatoBold uppercase text-11px mt-16 tracking-2 px-4 py-2 flex flex-row items-center gap-2 cursor-pointer pb-8"
              onClick={() => addToWishlist(vino)}
            >
              <LineaDiagonale />
              <FormattedMessage id="tenute.aggiungi_wishlist" />
            </button>
          )}

          {isInWishlist && (
            <div className="font-LatoBold uppercase text-11px mt-24 tracking-2 px-4 py-2 flex flex-row items-center gap-2 pb-8">
              <WishlistIcon color="black" />
              <div className="ml-4">
                <FormattedMessage id="tenute.aggiunto_wishlist" />
              </div>
            </div>
          )}
        </motion.div> */}

				{/* Formato bottiglie */}
				<motion.div
					className={`Tenuta__Formati grid grid-cols-6 items-end gap-4 ${
						isSmallScreen ? "" : "lg:absolute bottom-0 left-0 right-0 px-12 mb-32"
					}`}
					initial={{ opacity: 0, y: 25 }}
					animate={{ opacity: 1, y: 0, transition: { type: "tween", duration: 0.6, delay: 0.2 } }}
					exit={{ opacity: 0 }}
				>
					<AnimatePresence>
						{vino.formati
							.sort((a, b) => a.formato - b.formato)
							.map((f, i) => (
								<FormatoBottiglia formato={f.formato} key={i} k={i} />
							))}
					</AnimatePresence>
				</motion.div>

				{/* Footer */}
				<motion.div className={`${isSmallScreen ? "mt-8" : "lg:absolute bottom-0 left-0 right-0 px-12 py-8"}`}>
					<div className="border border-gray border-t mb-6"></div>
					<div className="flex items-center justify-between">
						<a
							target="_blank"
							rel="noopener noreferrer"
							className="font-BodoniBook text-xl text-brown"
							href={getLinkCorretto(vino.brand.link_sito)}
						>
							<FormattedMessage id="tenute.prodotti.visita_il_sito" />
						</a>
					</div>
				</motion.div>
			</div>
		</Container>
	);
}
