import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { saveState } from './redux/local-storage'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import createStore from './redux/create-store'

import './styles/main.css'
import './styles/fonts.css'
import './styles/rodal.css'
import './styles/scrollbar.css'
import './styles/global.css'

const store = createStore()

store.subscribe(() => {
  saveState(store.getState())
})

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)
