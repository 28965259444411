import { createStore as reduxCreateStore, combineReducers } from "redux"
import { composeWithDevTools } from "@redux-devtools/extension"
import filtersReducer from "./filters-reducer"
import wishListReducer from "./wishlist-reducer"
import menuReducer from "./menu-reducer"
import menuViniReducer from "./menu-vini-reducer"
import authReducer from "./auth-reducer"
import productsReducer from "./products-reducer"
import posReducer from "./pos-reducer"
import tenuteReducer from "./tenute-reducer"
import winesReducer from "./wines-reducer"
import winesWishlistReducer from "./wines-wishlist-reducer"
import giftBoxListingReducer from "./giftbox-listing-reducer"
import { loadState } from "./local-storage"

const createStore = () => {
  const persistedState = loadState()

  const rootReducer = combineReducers({
    filters: filtersReducer,
    wishList: wishListReducer,
    menu: menuReducer,
    auth: authReducer,
    products: productsReducer,
    posproducts: posReducer,
    menuVini: menuViniReducer,
    tenute: tenuteReducer,
    wines: winesReducer,
    winesWishlist: winesWishlistReducer,
    giftBoxes: giftBoxListingReducer,
  })

  return reduxCreateStore(rootReducer, persistedState, composeWithDevTools())
}

export default createStore
